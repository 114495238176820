<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Statistics
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="4">
            <v-select
              v-model="selectedClientId"
              :items="clientNames"
            />
          </v-col>
        </v-row>

        <v-row v-if="description" no-gutters>
          <v-col>
            <div class="text-body-1">{{ description }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="contactEmail" cols="3">
            <v-icon class="mr-1">mdi-email</v-icon>
            <a :href="`mailto:${contactEmail}`" target="_blank">{{ contactEmail }}</a>
          </v-col>
          <v-col v-if="url" cols="5">
            <v-icon class="mr-1">mdi-web</v-icon>
            <a :href="url" target="_blank">{{ url }}</a>
          </v-col>
          <v-spacer />
        </v-row>

        <v-row v-if="statistics" no-gutters>
          <v-col cols="2" class="mr-4">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Year
                  </th>
                  <th class="text-left">
                    Count
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="value in yearlyValues"
                  :key="value.year"
                >
                  <td>{{ value.year }}</td>
                  <td>{{ value.count }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="6">
              <v-sparkline
                :value="counterValues"
                :labels=counterYears
                auto-draw
              />
          </v-col>
        </v-row>

        <v-row v-if="prefixes" no-gutters>
          <v-col>
            Prefix: {{ prefixes }}
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import environment from '@/shared/utils/environment'
import { components } from 'vue-component-library'
import backendServices from '@/shared/services/backend-services'

const { applicationState } = components

export default {
  name: 'StatisticsDoiView',
  data () {
    return {
      selectedClientId: environment.clientId,
      clients: undefined,
      statistics: undefined,
      yearlyCounts: {}
    }
  },
  computed: {
    clientNames () {
      if (this.clients) {
        const names = this.clients?.map(client => ({
          value: client?.id,
          text: client?.attributes.name,
        }))
        // Add 'tereno' as an additional item
        names.push({ value: 'external-tereno', text: 'GFZ Data Services: TERENO' })
        return names || []
      } else {
        return []
      }
    },
    selectedClient () {
      if (this.selectedClientId === 'external-tereno') {
        return {
          attributes: {
            description: 'GFZ Data Services is a repository for research data and scientific software across all Earth System Sciences, hosted at GFZ. The curated data are archived, persistently accessible and published with digital object identifier (DOI). Datasets published via GFZ Data Services range from large dynamic datasets from data intensive global monitoring networks with real-time acquisition, to international services in geodesy and geophysics, to the full suite of small and highly heterogeneous datasets collected by individual researchers or small teams ("long-tail data"). In addition to the DOI registration and data archiving itself, GFZ Data Services team offers comprehensive consultation by domain scientists and IT specialists.',
            prefixes: '10.5880',
            contactEmail: 'kelger@gfz-potsdam.de',
            url: 'https://dataservices.gfz-potsdam.de/portal/?q=tereno',
          }
        }
      } else {
        return this.clients
          ?.find(client => client.id === this.selectedClientId)
      }
    },
    prefixes () {
      if (this.selectedClientId === 'external-tereno') {
        return '10.5880/tereno' // Set default prefixes for external-tereno
      } else if (this.selectedClient?.relationships) {
        return this.selectedClient
          ?.relationships.prefixes.data.map(({ id }) => id).join(', ')
      } else {
        return ''
      }
    },
    description () {
      return this.selectedClient?.attributes.description
    },
    contactEmail () {
      return this.selectedClient?.attributes.contactEmail
    },
    url () {
      return this.selectedClient?.attributes.url
    },
    yearlyValues () {
      if (this.selectedClientId === 'external-tereno') {
        return Object.keys(this.yearlyCounts).map(year => ({
          year: parseInt(year),
          count: this.yearlyCounts[year]
        }))
      } else {
        return this.statistics
          ? Object.entries(this.statistics?.temporal)
            .filter(([key, value]) => key !== 'this_month')
            .map(([, [{ title: year, count }]]) => ({ year, count }))
            .sort(({ year: first }, { year: second }) => first - second)
          : []
      }
    },
    counterYears () {
      return this.yearlyValues.map(({ year }) => year)
    },
    counterValues () {
      return this.yearlyValues.map(({ count }) => count)
    },
  },
  methods: {
    showCount (event) {
      // Get the coordinates of the click event
      const index = Math.round(event.offsetX / (event.target.clientWidth / this.counterValues.length))

      // Determine the count value at the clicked position
      const count = this.counterValues[index]

      if (count !== undefined) {
        alert(`Count: ${count}`)
      }
    },
    async fetchDOIStatistics () {
      try {
        if (this.selectedClientId === 'external-tereno') {
          const apiUrl = 'https://api.datacite.org/dois?page[size]=10000&prefix=10.5880&query=tereno'
          const response = await axios.get(apiUrl)
          const yearlyCounts = response.data.meta.published.reduce((acc, item) => {
            acc[item.id] = item.count
            return acc
          }, {})
          this.yearlyCounts = yearlyCounts
        } else {
          const { data: result } = await backendServices.getDataciteClientStatistics(this.selectedClientId)
          this.statistics = result.data
        }
      } catch (exception) {
        applicationState.addError(exception.toString())
        console.error(exception)
      }
    },
    async fetchClients () {
      try {
        const { data: result } = await backendServices.getDataciteClients()
        this.clients = result.data
      } catch (exception) {
        applicationState.addError(exception.toString())
        console.error(exception)
      }
    },
  },
  watch: {
    selectedClientId: {
      immediate: true,
      async handler () {
        try {
          await this.fetchDOIStatistics()
        } catch (exception) {
          applicationState.addError(exception.toString())
          console.error(exception)
        }
      },
    },
  },
  async created () {
    this.fetchClients()
  },
}
</script>
